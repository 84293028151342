import { useContext } from "react";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

library.add(fas)

const chipStyle = {
    backgroundColor: 'gray',
    color: 'white',
    fontSize: '10px',
    height: '20px',
    width: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
    margin: '1px'
};


export default function App(data) {
    // console.log(data);

    const navigate = useNavigate();
    const handleClick = (label) => {
        console.log(label);
        navigate(`/tag?tag=${label}`);
    };

    return (
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {data.data.map((element, index) => (
                <Card key={index} sx={{
                    width: 320,
                    // height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 0,
                    borderRadius: 2,
                    mr: 2
                }}
                >
                    <CardContent
                        sx={{
                            width: 320,
                            // height: 300,
                        }}
                    >
                        <Typography sx={{
                            fontSize: 14,
                        }}
                            color="text.secondary" gutterBottom>
                            <a href={element.watch_uri}><img src={element.thumbnail_url}></img></a>
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 14, height: 40, width: 300 }} color="text.primary">
                            {element.name}<br></br>
                            <ScheduleIcon sx={{ fontSize: 12 }} color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                        </Typography>
                        <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                        <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                        <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                        <Typography variant="body2">
                        </Typography>
                    </CardContent>
                </Card>
                // </Grid>
            ))
            }
        </ScrollMenu >
    );
}

const Arrow = ({ children, onClick }) => {
    return (
        <>
            <button
                onClick={onClick}
                style={{
                    backgroundColor: "white",
                    border: 'none',
                    borderColor: 'none',
                    borderRadius: '30px',
                    color: 'none'
                }}
            >
                {children}
            </button>
        </>
    );
};

const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

    return (
        <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()} size="2xs">
            <FontAwesomeIcon
                icon="fas fa-angle-left"
                size="3x"
                color={isFirstItemVisible ? "white" : "black"}
            />
        </Arrow>
    );
}

const RightArrow = () => {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

    return (
        <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <FontAwesomeIcon icon="fas fa-angle-right"
                size="3x"
                color={isLastItemVisible ? "white" : "black"}
            />
        </Arrow>
    );
}
