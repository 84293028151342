import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import { useAuth } from './hooks/use-auth';
import { SignIn } from './pages/SignIn';
import 'react-tabs/style/react-tabs.css';
import {
  Typography,
  Box,
} from '@mui/material';


//自作
import Watch from './pages/WatchVideo';
import Search from './components/Search';
import DetailedSearch from './components/DetailedSearch';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/Home';
import Print from "./components/DefaultMoviesPrint";
import TagSearch from './components/TagSearch';

// console.log(process.env)
// const ranking_data = {
//   userid: process.env.REACT_APP_VIMEO_USERID,
//   albumid: process.env.REACT_APP_VIMEO_ALBUMID,
//   order: process.env.REACT_APP_VIMEO_RAINKING_SEARCH_OLDER,
//   display_count: process.env.REACT_APP_VIMEO_RAINKING_DISPLAY_COUNT,
//   apiuri: process.env.REACT_APP_VIMEO_RAINKING_API_ENDPOINT,
//   condition : false
// };

// const new_data = {
//   userid: process.env.REACT_APP_VIMEO_USERID,
//   albumid: process.env.REACT_APP_VIMEO_ALBUMID,
//   order: process.env.REACT_APP_VIMEO_NEW_SEARCH_OLDER,
//   display_count: process.env.REACT_APP_VIMEO_NEW_DISPLAY_COUNT,
//   apiuri: process.env.REACT_APP_VIMEO_NEW_API_ENDPOINT,
//   condition: true
// };

// const recommend_data = {
//   userid: process.env.REACT_APP_VIMEO_USERID,
//   albumid: process.env.REACT_APP_VIMEO_ALBUMID,
//   order: process.env.REACT_APP_VIMEO_RECOMMEND_SEARCH_OLDER,
//   display_count: process.env.REACT_APP_VIMEO_RECOMMEND_DISPLAY_COUNT,
//   apiuri: process.env.REACT_APP_VIMEO_RECOMMEND_API_ENDPOINT
// };

const baseurl = process.env.REACT_APP_VIMEO_BASE_APIENDPOINT

const ranking_data = {
  title: "人気ランキング(閲覧数順)",
  userid: 117387536,
  albumid: 9735879,
  order: "plays",
  display_count: 50,
  apiuri: "/v1/getmovies",
  condition: false
};

const new_data = {
  title: "新着動画",
  userid: 117387536,
  albumid: 9735879,
  order: "date",
  display_count: 50,
  apiuri: "/v1/getmovies",
  condition: true
};

const recommend_data = {
  title: "スタッフのおすすめ動画",
  userid: 117387536,
  // albumid: 9735879,
  albumid: 9735879,
  order: "plays",
  display_count: 50,
  apiuri: "/v1/showcasemovies",
};

const senioryoga_data = {
  title: "シニアヨガ",
};

const maternityyoga_data = {
  title: "マタニティヨガ",
};

const search_data = {
  title: "",
};

const yogaanatomy_data = {
  title: "ヨガ解剖学",
};

const watchvideo_data = {
  albumid: 9735879,
};

function App() {


  const auth = useAuth();
  
  if (auth.isLoading) {
    return <div></div>;
  }

  const PrivateDashboard = () => {
    // console.log("時間経過後のリダイレクト実装");

    let navigate = useNavigate();
    useEffect(() => {
      // console.log("useEffect");
      setTimeout(() => {
        navigate({ pathname: '/' });
      }, 3 * 1000);
    }, [navigate]);

    return (
      <PrivateRoute>
        <Header />
        <Box sx={{ justifyContent: 'center', alignItems: "center", textAlign: "center", height: '100%' }}>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mt: 4, mb: 2, fontSize: 20 }}>ようこそ！ {auth.username} さん！</Typography>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, fontSize: 20 }}>3秒後にトップページに移動します。</Typography>
        </Box>
        <Footer />
      </PrivateRoute >
    )
  };

  const Logout = () => {
    console.log("時間経過後のリダイレクト実装");

    let navigate = useNavigate();
    useEffect(() => {
      // console.log("useEffect");
      setTimeout(() => {
        navigate({ pathname: '/' });
      }, 3 * 1000);
    }, [navigate]);

    return (
      <>
        <Header />
        <Box sx={{ justifyContent: 'center', alignItems: "center", textAlign: "center", height: '100%' }}>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mt: 4, mb: 2, fontSize: 20 }}>ログアウトしました。</Typography>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, fontSize: 20 }}>3秒後にトップページに移動します。</Typography>
        </Box>
        <Footer />
      </>
    )
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home ranking_data={ranking_data} new_data={new_data} recommend_data={recommend_data} baseurl={baseurl} />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signin/:uri" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/dashboard" element={<PrivateDashboard />} />
        <Route path="/watch/:videoid" element={<Watch baseurl={baseurl} param={watchvideo_data} />} />
        <Route path="/search" element={<Search baseurl={baseurl} param={search_data} />} />
        <Route path="/senioryoga" element={<Search baseurl={baseurl} param={senioryoga_data} />} />
        <Route path="/maternityyoga" element={<Search baseurl={baseurl} param={maternityyoga_data} />} />
        <Route path="/yogaanatomy" element={<Search baseurl={baseurl} param={yogaanatomy_data} />} />
        <Route path="/detailedsearch" element={<DetailedSearch baseurl={baseurl} />} />
        <Route path="/tag" element={<TagSearch baseurl={baseurl} />} />
        <Route path="/rankingvideos" element={<Print param={ranking_data} baseurl={baseurl} />} />
        <Route path="/newvideos" element={<Print param={new_data} baseurl={baseurl} />} />
        <Route path="/recommendvideos" element={<Print param={recommend_data} baseurl={baseurl} />} />
        <Route path="*" element={<p>not found </p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;