import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Modal from '@mui/material/Modal';
import DetailedSearch from './pages/DetailedSearch';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

// <p>下記はコレクションの動画です</p>
function Items({ currentItems, itemOffset, itemsCount }) {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const url = useLocation().pathname
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const chipStyle = {
    backgroundColor: 'gray',
    color: 'white',
    fontSize: '10px',
    height: '20px',
    width: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
    margin: '1px'
  };

  const navigate = useNavigate();
  const handleClick = (label) => {
    console.log(label);
    navigate(`/tag?tag=${label}`, { replace: true }); // 同じページにリダイレクト
    window.location.reload()
  };

  function formatDate(dt) {
    var date = new Date(dt);
    var y = date.getFullYear();
    var m = ('00' + (date.getMonth() + 1)).slice(-2);
    var d = ('00' + date.getDate()).slice(-2);
    return (y + '年' + m + '月' + d + '日');
  }

  if (url.includes("search")) {
    return (
      <>
        <Box sx={{ fontSize: 20, ml: 3, width: 'auto' }}>
          <Typography sx={{ fontSize: 25, width: 'auto', mb: 5, fontFeatureSettings: `'palt' on`, }} color="text.primary">
            {query.get("title") != null && "動画タイトル「" + query.get("title") + "」"}
            {query.get("title") != null && <br></br>}
            {query.get("auther") && "講師「" + query.get("auther") + "」"}
            {query.get("auther") && <br></br>}
            {query.get("level") && "レベル／ジャンル「" + query.get("level") + "」"}
            {query.get("level") && <br></br>}
            {query.get("category") && "カテゴリ「" + query.get("category") + "」"}
            {query.get("category") && <br></br>}
            の検索結果
          </Typography>
          {currentItems && currentItems.length != 0 &&
            <Typography noWrap sx={{ fontSize: 20, height: 40, width: 500 }} color="text.primary">
              該当<span style={{ fontSize: 25 }}>{itemsCount}</span>件(うち{itemOffset + 1}〜{itemOffset + currentItems.length}件)を表示 ※最大表示件数50件
            </Typography>
          }
          {currentItems == "" &&
            <Typography noWrap sx={{ fontSize: 20, height: 60, width: 500 }} color="text.primary">
              該当0件<br></br>
              他の検索条件で検索してみてください m(_ _)m
            </Typography>
          }
        </Box>
        <>
          <Button
            onClick={handleOpen}  //modal追加
            sx={{ color: 'black', ml: 2, underline: 'always', textDecoration: 'underline' }}
          >
            再度詳細検索はこちら
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <DetailedSearch />
            </Box>
          </Modal>
        </>
        {/* } */}

        <Grid
          container spacing={2}
          margin="auto"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {
            currentItems &&
            currentItems.map((element, index) => {
              return (
                <Grid item xs="auto" xl="auto" key={index}>
                  <Card sx={{
                    width: 320,
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 0,
                  }}
                  >
                    <CardContent
                      sx={{
                        width: 320,
                        height: 300,
                      }}
                    >
                      <Typography sx={{
                        fontSize: 14,
                      }}
                        color="text.secondary" gutterBottom>
                        <a href={element.watch_uri}><img src={element.thumbnail_url}></img></a>
                      </Typography>
                      <Typography noWrap sx={{ fontSize: 14, height: 40, width: 300 }} color="text.primary">
                        {element.name}<br></br>
                        <ScheduleIcon sx={{ fontSize: 12 }} color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                      </Typography>
                      <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                      <Typography variant="body2">
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                // </>
              )
            })
          }
        </Grid >
      </>
    )
  } else if (url.includes("tag")) {
    return (
      <>
        <Typography sx={{ fontSize: 20, width: 'auto', ml: 4, mb: 2, fontFeatureSettings: `'palt' on`, }} color="text.primary">
          {query.get("tag") != null && "タグ：" + query.get("tag")}
        </Typography>
        <Grid
          container spacing={2}
          margin="auto"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {
            currentItems &&
            currentItems.map((element, index) => {
              return (
                <Grid item xs="auto" xl="auto" key={index}>
                  <Card sx={{
                    width: 320,
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 0,
                  }}
                  >
                    <CardContent
                      sx={{
                        width: 320,
                        height: 300,
                      }}
                    >
                      <Typography sx={{
                        fontSize: 14,
                      }}
                        color="text.secondary" gutterBottom>
                        <a href={element.watch_uri}><img src={element.thumbnail_url}></img></a>
                      </Typography>
                      <Typography noWrap sx={{ fontSize: 14, height: 40, width: 300 }} color="text.primary">
                        {element.name}<br></br>
                        <ScheduleIcon sx={{ fontSize: 12 }} color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                      </Typography>
                      <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                      <Typography variant="body2">
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                // </>
              )
            })
          }
        </Grid >
      </>
    )
  } else {

    return (
      <>
        <Grid
          container spacing={2}
          margin="auto"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {
            currentItems &&
            currentItems.map((element, index) => {
              return (
                <Grid item xs="auto" xl="auto" key={index}>
                  {url === "/rankingvideos" && <Typography sx={{ ml: 2, fontSize: 20 }} color="text.primary">{index + 1 + itemOffset}位</Typography>}
                  {url === "/newvideos" && itemOffset == 0 && <Typography sx={{ ml: 2, fontSize: 20 }} color="text.primary">{(formatDate(currentItems[0].created_time) == formatDate(element.created_time) ? "New!" : <br></br>)}</Typography>}
                  <Card sx={{
                    width: 320,
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 0,
                  }}
                  >
                    <CardContent key={index}
                      sx={{
                        width: 320,
                        height: 300,
                      }}
                    >
                      <Typography sx={{
                        fontSize: 14,
                      }}
                        color="text.secondary" gutterBottom>
                        <a href={element.watch_uri}><img src={element.thumbnail_url}></img></a>
                      </Typography>
                      <Typography noWrap sx={{ fontSize: 14, height: 40, width: 300 }} color="text.primary">
                        {element.name}<br></br>
                        <ScheduleIcon sx={{ fontSize: 12 }} color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                      </Typography>
                      <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                      <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                      <Typography variant="body2">
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })
          }
        </Grid >
      </>
    )
  }
}

function PaginatedItems({ itemsPerPage, items }) {
  // console.log("PaginatedItems");
  // console.log(items);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} itemOffset={itemOffset} itemsCount={items.length} />
      <ReactPaginate
        nextLabel="次へ"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="前へ"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default PaginatedItems;