import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import RecommendIcon from '@mui/icons-material/Recommend';

const style = {
    display: 'flex',
    mt: 2,
    textalign: "center",
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: '#DCDCDC',
};

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'© 2022 '}
            <Link color="inherit" href="https://www.underthelight.co.jp/">
                UNDER THE LIGHT
            </Link>{' '}
            , INC.
        </Typography>
    );
}

const SnsButtonList = () => {
    const [value, setValue] = useState("");
    const navigate = useNavigate();
    return (
        <Box sx={{ bgcolor: '#DCDCDC'}}>
            <Box sx={style}>
                <a href="https://facebook.com/utlyoga">
                    <FacebookIcon sx={{ color: "#3b5998", fontSize: 30 }} />
                </a>
                <a href="https://twitter.com/utlyoga">
                    <TwitterIcon sx={{ color: "#1da1f2", fontSize: 30 }} />
                </a>

                <a href="https://instagram.com/utlyoga">
                    <InstagramIcon sx={{ color: "#e1306c", fontSize: 30 }} />
                </a>
                <a href="https://youtube.com/user/UTLassociate">
                    <YouTubeIcon sx={{ color: "#ff0000", fontSize: 30 }} />
                </a>
            </Box>
            <Copyright sx={{ mt: 1, display: { xs: 'none', md: 'block' }}} />
            <Copyright sx={{ mt: 1,  mb: 7, display: { xs: 'block', md: 'none' }}} />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', md: 'none' }}} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        console.log(newValue);
                        navigate({ pathname: `${newValue}` });
                        window.location.reload()
                    }}
                >
                    <BottomNavigationAction value="/newvideos" sx={{ flexGrow: 1 }} label="新着" icon={<FiberNewIcon />} />
                    <BottomNavigationAction value="/rankingvideos" sx={{ flexGrow: 1 }} label="人気" icon={<LocalFireDepartmentIcon />} />
                    <BottomNavigationAction value="/recommendvideos" sx={{ flexGrow: 1 }} label="おすすめ" icon={<RecommendIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}

export default SnsButtonList