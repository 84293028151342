import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ProvideAuth } from './hooks/use-auth';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <HelmetProvider>
        <Helmet>
          <title>本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50</title>
          <meta name="description" content="サイトの説明文です" />
        </Helmet>
        <App />
      </HelmetProvider>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
