import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import '../App.css';

import axiosBase from 'axios';
import 'react-tabs/style/react-tabs.css';
import {
    Backdrop,
    CircularProgress,
    Grid,
    Box,
    Typography,
} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';

//自作ライブラリ
import PaginatedItems from '../Pagenate';
import Header from '../components/header';
import Footer from '../components/footer';
import Error from "../components/error";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ScheduleIcon from '@mui/icons-material/Schedule';

import Chip from '@mui/material/Chip';

//FAQメニュー
import Faq from '../components/Faq';
import { useMediaQuery } from 'react-responsive'

//metaタグ設定
import { Helmet, HelmetProvider } from 'react-helmet-async';

const chipStyle = {
    backgroundColor: 'gray',
    color: 'white',
    fontSize: '10px',
    height: '20px',
    width: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
    margin: '1px'
};

function formatDate(dt) {
    var date = new Date(dt);
    var y = date.getFullYear();
    var m = ('00' + (date.getMonth() + 1)).slice(-2);
    var d = ('00' + date.getDate()).slice(-2);
    return (y + '年' + m + '月' + d + '日');
}

const InfiniteScrollPage = (data) => {
    const url = useLocation().pathname

    const navigate = useNavigate();
    const handleClick = (label) => {
        console.log(label);
        navigate(`/tag?tag=${label}`, { replace: true }); // 同じページにリダイレクト
        window.location.reload()
    };

    return (
        <>
            <Grid
                container spacing={1} p={2}
                alignItems="center"
                justifyContent="center"
            >
                {
                    data &&
                    data.data.map((element, index) => (
                        <Grid item xs={12} xl={12} key={index}>
                            {url == "/rankingvideos" && <Typography sx={{ ml: 2, fontSize: 20 }} color="text.primary">{index + 1}位</Typography>}
                            {url == "/newvideos" && <Typography sx={{ ml: 2, fontSize: 20 }} color="text.primary">{(formatDate(data.data[0].created_time) == formatDate(element.created_time) ? "New!" : <></>)}</Typography>}
                            <Card sx={{
                                width: "100%",
                                height: "100%",
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: 0,
                            }}
                            >
                                <CardContent
                                    sx={{
                                        width: "45%",
                                        boxShadow: 0,
                                        p: 0,
                                        m: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <AspectRatio
                                        ratio="1/1"
                                    >
                                        <a href={element.watch_uri} >
                                            <img className="movie-image" src={element.thumbnail_url}>
                                            </img>
                                        </a>
                                    </AspectRatio>
                                </CardContent>
                                <CardContent
                                    sx={{
                                        width: "50%",
                                        height: "100%",
                                        boxShadow: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3, // 行数指定
                                            overflow: "hidden",
                                        }} color="text.primary">
                                        {element.name}<br></br>
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, width: "100%" }} color="text.primary">
                                        <ScheduleIcon fontSize="h6" color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                                    </Typography>
                                    <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                                    <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                                    <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}

const Print = (props) => {


    // console.log(data);
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState(true);
    const url = window.location.href
    const initialState = [];
    const [movielists, setMovielists] = useState(initialState);
    const location = useLocation();

    const isSmallScreen = useMediaQuery({ maxDeviceWidth: 599 }) // equivalent to 'sm' breakpoint
    const isMediumScreen = useMediaQuery({ minDeviceWidth: 600, maxDeviceWidth: 959 }) // equivalent to 'md' breakpoint
    const isLargeScreenOrMore = useMediaQuery({ minDeviceWidth: 960 }) // 'lg' breakpoint and more

    const pathname = useLocation().pathname
    let title, description;

    switch (pathname) {
        case '/newvideos':
            title = "新着動画｜本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50";
            description = "「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。このページでは新着動画を紹介しています。";
            break;
        case '/rankingvideos':
            title = "人気動画｜本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50";
            description = "「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。このページでは人気動画をラインキングで紹介しています。";
            break;
        case '/recommendvideos':
            title = "スタッフのおすすめ動画｜本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50";
            description = "「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。このページではスタッフのおすすめ動画を紹介しています。";
            break;
        default:  // 通常はトップページ（'/'）に対するデフォルト値を設定します
            title = "本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50";
            description = "「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。新着動画、人気動画、おすすめ動画等をランキングで紹介しています。";
            break;
    }

    const getParameters = new Promise(function (resolve, reject) {
        // console.log("getParameters");
        const axios = axiosBase.create({
            baseURL: props.baseurl,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'D02MakhE9Sp70d8UJPEx',
            },
            responseType: 'json'
        })
        return (axios.get(`/v1/getparameters`)
            .then(res => {
                resolve(res.data.start_time);
            })
            .catch(e => {
                console.log(e)
                reject("パラメータ取得エラー")
            }));
    })

    useEffect(() => {
        setOpen(!open);
        // console.log(`useEffectが実行されました!`)

        ReactGA.initialize(process.env.REACT_APP_TRACKINGID);
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });

        const getmovies = new Promise(async function (resolve, reject) {
            const axios = axiosBase.create({
                baseURL: props.baseurl,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            if (props.param.condition == true) {
                try {
                    props.param.start_time = await getParameters;
                } catch (err) {
                    console.log(err);
                }
            }
            return (axios.post(props.param.apiuri, props.param)
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    console.log(e)
                    reject("動画取得エラー")
                }));
        })

        Promise.all([getmovies])
            .then((values) => {
                setMovielists(values[0]);
                setOpen(false);
            })
            .catch((e) => {
                console.log(e);
                setResult(!result);
                setOpen(false);
            })
            .finally(() => {
                console.log('結果に関係なく処理')
                setOpen(false);
            })
    }, []) // 第2引数には副作用関数の実行タイミングを制御する依存データを記述  

    if (result) {

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <Header />
                {
                    isLargeScreenOrMore && (
                        <Box sx={{ mt: 15, display: { xs: 'none', md: 'block', sm: 'block' } }}>
                            <Grid container spacing={1} p={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{ ml: 4, fontSize: 20 }}>{props.param.title}{(movielists.length !== 0 && props.param.condition == true) && "(" + formatDate(movielists[0].created_time) + "更新)"}</Typography>
                                    <PaginatedItems itemsPerPage={10} items={movielists} />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }
                {
                    (isSmallScreen || isMediumScreen) && movielists && (
                        <Box sx={{ mt: 10 }}>
                            <InfiniteScrollPage data={movielists} />
                        </Box>
                    )
                }
                {/* <Grid item xs={12}>
                            <InfiniteScrollPage data={movielists} />
                        </Grid>
                    </Grid>
                </Box> */}
                <Faq />
                <Footer title="test" url={url} />
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
                        }
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop >
                </div>
            </div >
        )
    } else {
        return (
            <Error />
        )
    }
};


export default Print;