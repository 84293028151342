import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import axiosBase from 'axios';
import Vimeo from '@u-wave/react-vimeo';
import Modal from '@mui/material/Modal';
import {
    Typography,
    Backdrop,
    CircularProgress,
    Box,
    Grid,
    Divider
} from '@mui/material';

import ColumnPaginatedItems from '../ColumnPagenate';
import Header from '../components/header';
import Footer from '../components/footer';
import './styles.css';
import styled from '@emotion/styled'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import Error from "../components/error";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import Chip from '@mui/material/Chip';

import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share'
import Faq from '../components/Faq';

const ButtonWrapper = styled.div`
    padding-right: 12px;
    padding-top: 12px;
  `
const style = {
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    width: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    border: 1,
    p: 4,
};

const chipStyle = {
    backgroundColor: 'gray',
    color: 'white',
    fontSize: '1em',
    height: '24px',
    width: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
    margin: '1px'
};

const Watch = (data) => {
    const baseurl = data.baseurl
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState(true);
    const [load, setLoad] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const url = window.location.href
    const title = "シェアします"
    const { videoid } = useParams(null);

    const location = useLocation();

    const navigate = useNavigate();
    const handleClick = (label) => {
        console.log(label);
        navigate(`/tag?tag=${label}`);
    };

    // console.log(data)
    // console.log(videoid);
    const video = {
        videoid: videoid,
        albumid: data.param.albumid,
    }

    //vimeoid/コレクションタイトル/動画タイトル/講師名(タグの一番目)/動画説明文/コレクション
    //vimeoidからコレクションが取得できる
    const initialState = [];
    const [movieinfo, setMovieinfo] = useState(initialState);

    useEffect(() => {
        setLoad(!load);
        // console.log(process.env.REACT_APP_TRACKINGID);
        ReactGA.initialize(process.env.REACT_APP_TRACKINGID);
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });

        //詳細動画情報取得/初期レンダリング
        const movie = new Promise(function (resolve, reject) {
            const axios = axiosBase.create({
                baseURL: baseurl,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            return (axios.post(`/v1/movieinfo`, video)
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    console.log(e)
                    reject("検索エラー")
                }));
        })

        Promise.all([movie])
            .then((values) => {
                setMovieinfo(values[0]);
                setLoad(false);
            })
            .catch((e) => {
                console.log(e);
                setResult(!result);
                setLoad(false);
            })
            .finally(() => {
                console.log('結果に関係なく処理')
                setLoad(false);
            })
    }, [])

    // console.log(movieinfo);

    if (result) {
        return (
            <PrivateRoute>
                <Header />
                <Grid container spacing={1} p={5}>

                    {/* スマホ表示 */}
                    <Grid item xs={12} sx={{ mt: 5, display: { md: 'none' } }}>
                        <Typography component="div" variant="h5">
                            {movieinfo.showcasetitle}
                        </Typography>

                        <Vimeo video={videoid}
                            // className="vimeo pc"
                            // width={1024} //16:9で設定
                            // height={576}
                            responsive
                            className="vimeo_movie"
                        ></Vimeo>
                        <Box sx={{ flexGrow: 1, display: "flex" }}>
                            <Typography component="div" variant="h5">
                                {movieinfo.movietilte}
                            </Typography>
                            {/* <Box display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                sx={{ flexGrow: 1 }}>
                                <Button
                                    onClick={handleOpen}
                                    sx={{ color: 'black', display: 'block', border: 1, mb: 1 }}
                                >
                                    <Typography variant="h7" color="text.primary" sx={{ fontWeight: 'bold' }} >
                                        シェア<ShareIcon size={5} />
                                    </Typography>
                                </Button>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Box>
                                            <Typography component="div" variant="h5" align="center">
                                                動画をシェア！
                                            </Typography>
                                        </Box>
                                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'center' }}>
                                            <ButtonWrapper>
                                                <FacebookShareButton url={url}>
                                                    <FacebookIcon size={50} />
                                                </FacebookShareButton>
                                            </ButtonWrapper>
                                            <ButtonWrapper>
                                                <LineShareButton url={url} >
                                                    <LineIcon size={50} />
                                                </LineShareButton>
                                            </ButtonWrapper>
                                            <ButtonWrapper>
                                                <TwitterShareButton title={title} url={url} >
                                                    <TwitterIcon size={50} />
                                                </TwitterShareButton>
                                            </ButtonWrapper>
                                            <ButtonWrapper
                                                onClick={() => {
                                                    navigator.clipboard.writeText(url).then(
                                                        () => { alert("クリップボードにコピーしました") },
                                                        () => { alert("コピーに失敗しました") }
                                                    );
                                                }}
                                            >
                                                <ContentCopyIcon sx={{ fontSize: "50px" }} />
                                            </ButtonWrapper>
                                        </Box>
                                    </Box>
                                </Modal>
                            </Box> */}
                        </Box>
                        <Divider />
                        {/* <Typography component="div" variant="h6" color="text.secondary">
                            講師:{typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 1 && movieinfo.tags[0].name}
                        </Typography>
                        <Typography component="div" variant="h6" color="text.secondary">
                            レベル／ジャンル:{typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 2 && movieinfo.tags[1].name}
                        </Typography>
                        <Typography component="div" variant="h6" color="text.secondary">
                            カテゴリ:{typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 3 && movieinfo.tags[2].name}
                        </Typography>
                        <Typography component="div" variant="body-2" color="text.secondary">
                            {movieinfo.description}
                        </Typography> */}
                        <Typography component="div" variant="body-2" color="text.secondary">
                            {movieinfo.description}
                        </Typography>
                        <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 1 && movieinfo.tags[0].name} size="small" onClick={() => handleClick(movieinfo.tags[0].name)} />
                        <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 2 && movieinfo.tags[1].name} size="small" onClick={() => handleClick(movieinfo.tags[1].name)} />
                        <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 3 && movieinfo.tags[2].name} size="small" onClick={() => handleClick(movieinfo.tags[2].name)} />

                        {/* <Divider /> */}
                        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Typography component="div" variant="h5" visibility="hidden">
                                ここは透明
                            </Typography>
                            {
                                movieinfo.movielists &&
                                <ColumnPaginatedItems itemsPerPage={7} items={movieinfo.movielists} />
                            }
                        </Grid>
                    </Grid>

                    {/* PC表示表示 */}
                    <Grid item xs={12} sx={{ mt: 15, display: { xs: 'none', md: 'flex' } }}>
                        <Grid item xs={8} >
                            <Typography component="div" variant="h5">
                                {movieinfo.showcasetitle}
                            </Typography>

                            <Vimeo video={videoid}
                                // className="vimeo pc"
                                // width={1024} //16:9で設定
                                // height={576}
                                responsive
                                className="vimeo_movie"
                            ></Vimeo>
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <Typography component="div" variant="h5">
                                    {movieinfo.movietilte}
                                </Typography>
                                {/* <Box display="flex"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    sx={{ flexGrow: 1 }}>
                                    <Button
                                        onClick={handleOpen}
                                        sx={{ color: 'black', display: 'block', border: 1, mb: 1 }}
                                    >
                                        <Typography variant="h7" color="text.primary" sx={{ fontWeight: 'bold' }} >
                                            シェア<ShareIcon size={5} />
                                        </Typography>
                                    </Button>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Box>
                                                <Typography component="div" variant="h5" align="center">
                                                    動画をシェア！
                                                </Typography>
                                            </Box>
                                            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'center' }}>
                                                <ButtonWrapper>
                                                    <FacebookShareButton url={url}>
                                                        <FacebookIcon size={50} />
                                                    </FacebookShareButton>
                                                </ButtonWrapper>
                                                <ButtonWrapper>
                                                    <LineShareButton url={url} >
                                                        <LineIcon size={50} />
                                                    </LineShareButton>
                                                </ButtonWrapper>
                                                <ButtonWrapper>
                                                    <TwitterShareButton title={title} url={url} >
                                                        <TwitterIcon size={50} />
                                                    </TwitterShareButton>
                                                </ButtonWrapper>
                                                <ButtonWrapper
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(url).then(
                                                            () => { alert("クリップボードにコピーしました") },
                                                            () => { alert("コピーに失敗しました") }
                                                        );
                                                    }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: "50px" }} />
                                                </ButtonWrapper>
                                            </Box>
                                        </Box>
                                    </Modal>
                                </Box> */}
                            </Box>
                            <Divider />
                            <Typography component="div" variant="body-2" color="text.secondary">
                                {movieinfo.description}
                            </Typography>
                            <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 1 && movieinfo.tags[0].name} size="small" onClick={() => handleClick(movieinfo.tags[0].name)} />
                            <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 2 && movieinfo.tags[1].name} size="small" onClick={() => handleClick(movieinfo.tags[1].name)} />
                            <Chip style={chipStyle} label={typeof (movieinfo.tags) !== "undefined" && movieinfo.tags.length >= 3 && movieinfo.tags[2].name} size="small" onClick={() => handleClick(movieinfo.tags[2].name)} />
                            {/* <Divider /> */}
                        </Grid>
                        <Grid item xs={4} sx={{ display: { xs: 'none', md: 'block' }, ml: '0.5rem'}}>
                            <Typography component="div" variant="h5" visibility="hidden">
                                ここは透明
                            </Typography>
                            {/* <Typography component="div" variant="h5">
                                ここは透明
                            </Typography> */}
                            {
                                movieinfo.movielists &&
                                <ColumnPaginatedItems itemsPerPage={7} items={movieinfo.movielists} />
                            }
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>

                <Footer />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
                    }
                    open={load}
                >
                    <CircularProgress color="inherit" />
                </Backdrop >
            </PrivateRoute >
        )
    } else {
        return (
            <Error />
        )
    }
};

export default Watch;