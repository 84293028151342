import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import Header from '../components/header';
import Footer from '../components/footer';
// import {
//   Divider
// } from '@mui/material';

import MainImage from "../images/UTL_ONLINE.png";

const theme = createTheme();

export function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const params = useParams();
  // console.log("Signin Fuction呼び出し");

  const executeSignIn = async (event) => {
    event.preventDefault();
    const result = await auth.signIn(username, password);
    if (result.success) {
      if (params.uri?.includes("watch")) {
        navigate({ pathname: params.uri });
      } else {
        navigate({ pathname: '/dashboard' });
      }
    } else {
      alert(result.message);
    }
  };

  return (
    <>
      <Header />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              mt: 15,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // mt: 5
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              ログイン
            </Typography>
            <Box component="form" onSubmit={executeSignIn} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="ユーザ名"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ログイン
              </Button>
            </Box>
            {/* <Divider /> */}
            <Box sx={{ justifyContent: 'center', alignItems: "center", textAlign: "center" }}>
              <Typography sx={{ textAlign: 'center', mb: 2, fontSize: 12 }}>動画視聴の為には、</Typography>
              <Typography sx={{ textAlign: 'center', mb: 2, fontSize: 12 }}>UTL YOGA ONLINEゴールドメンバーへの入会が必要です。</Typography>
              <Typography sx={{ textAlign: 'center', mb: 2, fontSize: 12 }}>ゴールドメンバーの詳細・申込みは<a href="https://yogahodai.underthelight.jp/gold" target="_blank">こちら</a></Typography>
              <img src={MainImage} className="MainImage" alt="MainImage" width="100%"></img>
            </Box>
          </Box>
        </Container>

      </ThemeProvider>
      <Footer />
    </>

  );
}

export default SignIn;