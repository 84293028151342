import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Paper } from '@mui/material';


function Items({ currentItems }) {
  return (
    // <Grid
    //   container spacing={1}
    //   justifyContent="flex-start"
    //   alignItems="flex-start"
    // >
    // <Grid item xs={12}>
    // <Paper>12</Paper>
    <>
      {
        currentItems &&
        currentItems.map((element, index) => {
          return (
            // <Grid item xs="auto" xl="auto" key={index}>
            // Grid item xs={12} 
            // <Grid item xs={12} key={index}>
            // <Card sx={{
            //   // maxWidth: 400,
            //   width: 'auto',
            //   height: 100,
            //   display: 'flex',
            //   flexDirection: 'column',
            //   m: '0.5rem',
            //   p: '0.5rem',
            //   // boxShadow: 1,
            //   // borderRadius: 2
            // }}
            // >
            //   <Box sx={{
            //     display: 'flex',
            //     flexDirection: 'row',
            //     height: 100,
            //     //  maxWidth: 400,
            //     width: 'auto',
            //     alignItems: 'center'
            //   }}>
            //     <CardContent>
            //       <a href={element.watch_uri}><img src={element.thumbnail_url}></img></a>
            //     </CardContent>
            //     <CardContent sx={{ width: 'auto' }}>
            //       <Typography component="div" variant="body2">
            //         {element.name}
            //       </Typography>
            //       <Typography component="div" variant="body2" color="text.secondary">
            //         <ScheduleIcon fontSize="h6" color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
            //       </Typography>
            //     </CardContent>
            //   </Box>
            // </Card>
            <Card
              sx={{
                width: 'auto',
                // height: '1000',
                display: 'flex',
                flexDirection: 'row', // Changed from 'column' to 'row'
                m: '0.5rem',
                p: '0.5rem',
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  flexBasis: '50%',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '0.1rem',
                }}
              >
                <a href={element.watch_uri}>
                  <img
                    src={element.thumbnail_url}
                    alt={element.name}
                    // style={{ width: 100, height: 75, objectFit: 'cover' }} 
                    style={{ width: 130, height: 100, objectFit: 'cover' }}
                  />
                </a>
              </CardContent>
              <CardContent
                // sx={{
                //   flexGrow: 1,
                //   flexBasis: '50%',
                //   justifyContent: 'center',
                //   alignItems: 'center',
                // }}
                sx={{
                  flexGrow: 1,
                  flexBasis: '50%',
                  overflow: 'hidden',
                  // display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:last-child': { pb: '1rem' }
                }}
              >
                <Typography component="div" variant="body2">
                  {element.name}
                </Typography>
                <Typography component="div" variant="body2" color="text.secondary">
                  <ScheduleIcon fontSize="h6" color="text.secondary" />:{' '}
                  {Math.floor(element.duration / 60)}分
                  {Math.floor(element.duration % 60)}秒
                </Typography>
              </CardContent>
            </Card>
            // </Grid >
          )
        })
      }
    </>
    // </Grid>
    // </Grid >
  )
}

function ColumnPaginatedItems({ itemsPerPage, items }) {
  // console.log("PaginatedItems");
  // console.log(items);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Box sx=
        {{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 1,
          borderRadius: 2,
          // maxWidth: 400,
          width: 'auto',
          mb: '0.5rem',
        }}>
        <Items currentItems={currentItems} />
      </Box>
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="Columnpagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default ColumnPaginatedItems;