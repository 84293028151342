import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useLocation } from 'react-router-dom';

const PrivateRoute: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation(); // URL path や パラメータなど。JSのlocationと同じ
  const uri = "/signin/" + encodeURIComponent(location.pathname);
   return isAuthenticated ? <>{children}</> : <Navigate to={uri} />;
};

export default PrivateRoute;
