import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DetailedSearch from '../pages/DetailedSearch';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import { useForm } from 'react-hook-form';

// sm: 600dp〜
// md: 960dp〜
// lg: 1280dp〜
// xl: 1920dp〜

//ログイン処理
import { useAuth } from '../hooks/use-auth';
import Logo from "../images/220915_UTL_TOP50.png";
import Logo_SmartPhone from "../images/221027_UTLYO_TOP50_L.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SmartPhonePages = [
    { title: 'シニアヨガ', url: '/senioryoga?title=シニア' },
    { title: 'マタニティヨガ', url: '/maternityyoga?title=マタニティ' },
    { title: 'ヨガ解剖学', url: '/yogaanatomy?title=解剖学' },
]

const PcPages = [
    { title: '新着', url: '/newvideos' },
    { title: '人気', url: '/rankingvideos' },
    { title: 'おすすめ', url: '/recommendvideos' },
    { title: 'シニアヨガ', url: '/senioryoga?title=シニア' },
    { title: 'マタニティヨガ', url: '/maternityyoga?title=マタニティ' },
    { title: 'ヨガ解剖学', url: '/yogaanatomy?title=解剖学' },
]

const settings = ['ログアウト'];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {

        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    marginRight: 20,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchSmartphone = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.black, 0.15),
    // '&:hover': {

    //     backgroundColor: alpha(theme.palette.common.black, 0.25),
    // },
    marginLeft: 0,
    marginRight: 20,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    left: '80%',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    fontSize: 14,
    // border: 
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: "1px solid #ccc",
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(0)})`,
        paddingRight: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create('width'),
        width: '300px',
        [theme.breakpoints.up('sm')]: {
            height: '3ch',
            width: '30ch',
            '&:focus': {
                width: '30ch',
            },
        },
    },
}));


const ResponsiveAppBar = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let navigate = useNavigate();

    // const { control, handleSubmit } = useForm({
    //     mode: 'onChange',
    //     defaultValues: {
    //         title: ""
    //     },
    // });

    // const onSubmit = async (data) => {
    //     console.log(data.title);
    //     navigate({ pathname: `/search?title=${data.title}` });
    //     window.location.reload()

    // }

    // const validationRules = {
    //     name: {
    //         required: '空白です。入力してください。'
    //     }
    // }

    //ログイン周り
    const auth = useAuth();
    const { isAuthenticated } = useAuth();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (e) => {
        if (e.currentTarget.getAttribute('value') === '人気') {
            navigate({ pathname: `/rankingvideos` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === '新着') {
            navigate({ pathname: `/newvideos` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === 'おすすめ') {
            navigate({ pathname: `/recommendvideos` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === 'ログイン') {
            navigate({ pathname: `/signin` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === 'シニアヨガ') {
            navigate({ pathname: `/senioryoga?title=シニア` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === 'マタニティヨガ') {
            navigate({ pathname: `/maternityyoga?title=マタニティ` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === 'ヨガ解剖学') {
            navigate({ pathname: `/yogaanatomy?title=解剖学` });
            window.location.reload()
        } else if (e.currentTarget.getAttribute('value') === '初めての方へ') {
            window.open("https://yogahodai.underthelight.jp/userguide", '_blank');
        } else if (e.currentTarget.getAttribute('value') === 'ログイン') {
            navigate({ pathname: `/signin` });
            window.location.reload()
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        // console.log("ホップアップ閉じる");
        setAnchorElUser(null);
    };


    const handleCloseLogin = () => {
        navigate({ pathname: `/signin` });
        window.location.reload()
    };

    const handleCloseLogout = () => {
        auth.signOut()
        setAnchorElUser(null);
    };

    return (
        //スマホととPCで完全に分ける
        //md、横が900px以上の時はPC表示になる
        //flexGrowで幅設定できるので調整する 

        <AppBar position="fixed" color="inherit">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* PC表示 */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        align="left"
                        sx={{
                            mt: 1,
                            mb: 1,
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={Logo} className="logo" alt="logo" width="75"></img>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            target="_blank"
                            href="https://yogahodai.underthelight.jp/userguide"
                        >
                            <Typography textAlign="center">初めての方へ</Typography>
                        </Button>
                        {PcPages.map((page, index) => (
                            <Button
                                key={index}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                href={page.url}

                            >
                                <Typography textAlign="center">{page.title}</Typography>
                            </Button>
                        ))}
                    </Box>
                    <Search sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="キーワード：講師、ヨガの種類など"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    console.log(event.target.value);
                                    navigate({ pathname: `/search?title=${event.target.value}` });
                                    window.location.reload()
                                }

                            }}
                        />
                    </Search>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <MenuItem onClick={handleCloseNavMenu} value="ログイン" hidden={isAuthenticated} sx={{ border: 1 }}>
                            <LoginIcon />
                            <Typography sx={{ ml: 1 }} textAlign="center">ログイン</Typography>
                        </MenuItem>
                        <Tooltip title="Open settings" hidden={!isAuthenticated}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} >
                                <PersonIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting, index) => (
                                <MenuItem key={index} onClick={handleCloseLogout}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/* スマホ表示 */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem onClick={handleCloseNavMenu} value="初めての方へ">
                                <Typography textAlign="center">初めての方へ</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseLogin} hidden={isAuthenticated}>
                                <Typography textAlign="center">ログイン</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseLogout} hidden={!isAuthenticated}>
                                <Typography textAlign="center">ログアウト</Typography>
                            </MenuItem>
                            {SmartPhonePages.map((page, index) => (
                                <MenuItem key={index} onClick={handleCloseNavMenu} value={page.title}>
                                    <Typography textAlign="center">{page.title}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            // mr:10,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={Logo_SmartPhone} className="logo-image-smartphone" alt="logo" width="100"></img>
                    </Typography>

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <Button
                            onClick={handleOpen}  //modal追加
                            sx={{ color: 'black', underline: 'always', textDecoration: 'underline' }}
                        >
                            <SearchIcon />
                        </Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        // font-family: 'BIZ UDMincho', serif;
                        >
                            <Box sx={style}>
                                <SearchSmartphone sx={{ display: { xs: 'flex', md: 'none' } }}>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="キーワード：講師、ヨガの種類など"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                console.log(event.target.value);
                                                navigate({ pathname: `/search?title=${event.target.value}` });
                                                window.location.reload()
                                            }

                                        }}
                                    />
                                </SearchSmartphone>
                            </Box>
                        </Modal>
                    </Box>
                    {/* ざっくりPC表示 */}
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;
