import { useState, useEffect } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import '../App.css';

import axiosBase from 'axios';
import axiosRetry from "axios-retry";
import 'react-tabs/style/react-tabs.css';

import {
    Backdrop,
    CircularProgress,
    Box,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AspectRatio from '@mui/joy/AspectRatio';
import Chip from '@mui/material/Chip';

//自作ライブラリ
import PaginatedItems from '../Pagenate';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DetailedSearch from '../pages/DetailedSearch';
import Header from '../components/header';
import Footer from '../components/footer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    // width: 'auto',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const chipStyle = {
    backgroundColor: 'gray',
    color: 'white',
    fontSize: '10px',
    height: '20px',
    width: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
    margin: '1px'
};

const Search = (props) => {
    const baseurl = props.baseurl
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [load, setLoad] = useState(false);

    const initialState = [];
    const [searchsMovielists, setSearchMovielists] = useState(initialState);
    const location = useLocation();

    const navigate = useNavigate();
    const handleClick = (label) => {
        console.log(label);
        navigate(`/tag?tag=${label}`);
    };

    const search_data = {
        userid: 117387536,
        // title: query.get("title"),
        // auther: query.get("auther"),
        // level: query.get("level"),
        // category: query.get("category"),
        title: query.get("title"),
        auther: (query.get("auther") == "null" ? "" : query.get("auther")),
        level: (query.get("level") == "null" ? "" : query.get("level")),
        category: (query.get("category") == "null" ? "" : query.get("category")),
        order: "plays",
        display_count: 50
    };

    // console.log(search_data);

    useEffect(() => {
        setLoad(!load);

        ReactGA.initialize(process.env.REACT_APP_TRACKINGID);
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });

        // console.log('DetialedSearchでuseEffectが実行されました！')
        const searchmovies = async (search_data) => {
            const axios = axiosBase.create({
                baseURL: baseurl,
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            axiosRetry(axios, {
                retries: 3,
                shouldResetTimeout: true,
                retryDelay: (retryCount) => {
                    console.log(`retry attempt: ${retryCount}`);
                    return retryCount * 1000; // time interval between retries
                },
                retryCondition: (error) => {
                    console.log(error);
                    return true;
                }
            })

            // console.log(search_data)
            //タグの数で使うurlを分ける。
            if (search_data.auther == "" && search_data.level == "" && search_data.category == "") {
                var url = "/v1/searchmovies"
            } else {
                var url = "/v1/detailedsearchmovies"
            }

            const axios_response = await axios.post(url, search_data)
                .then(res => {
                    // console.log(res)
                    // console.log(res.data)
                    return res.data;
                })
            setSearchMovielists(axios_response);
        }

        Promise.all([searchmovies(search_data)]).then((values) => {
            // console.log(values);
            setLoad(false);
        });
    }, []) // 第2引数には副作用関数の実行タイミングを制御する依存データを記述  

    const InfiniteScrollPage = (data) => {
        // console.log(data);
        return (
            <>
                <Box sx={{ fontSize: 20, ml: 1, width: 300 }}>
                    <Typography sx={{ fontSize: 25, width: 'auto', mb: 5, fontFeatureSettings: `'palt' on`, }} color="text.primary">
                        {query.get("title") != null && "動画タイトル「" + query.get("title") + "」"}
                        {query.get("title") != null && <br></br>}
                        {query.get("auther") && "講師「" + query.get("auther") + "」"}
                        {query.get("auther") && <br></br>}
                        {query.get("level") && "レベル／ジャンル「" + query.get("level") + "」"}
                        {query.get("level") && <br></br>}
                        {query.get("category") && "カテゴリ「" + query.get("category") + "」"}
                        {query.get("category") && <br></br>}
                        の検索結果
                    </Typography>
                    {data.data && data.data.length != 0 &&
                        <Typography noWrap sx={{ fontSize: 20, height: 40, width: 'auto' }} color="text.primary">
                            該当<span style={{ fontSize: 25 }}>{data.data.length}</span>件　※最大表示件数50件
                        </Typography>
                    }
                    {data.data == "" &&
                        <Typography noWrap sx={{ fontSize: 20, height: 60, width: 'auto' }} color="text.primary">
                            該当0件<br></br>
                            他の検索条件で検索してみてください m(_ _)m
                        </Typography>
                    }
                </Box>
                <>
                    <Button
                        onClick={handleOpen}  //modal追加
                        // sx={{ color: 'black', ml: 2, underline: 'always', textDecoration: 'underline' }}
                        sx={{ color: 'black', underline: 'always', textDecoration: 'underline' }}
                    >
                        再度詳細検索はこちら
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <DetailedSearch />
                        </Box>
                    </Modal>
                </>
                {/* } */}
                <Grid
                    container spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    {
                        data &&
                        data.data.map((element, index) => (
                            <Grid item xs={12} xl={12} key={index}>
                                <Card sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: 0,
                                }}
                                >
                                    <CardContent
                                        sx={{
                                            width: "45%",
                                            boxShadow: 0,
                                            p: 0,
                                            m: 0,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <AspectRatio
                                            ratio="1/1"
                                        >
                                            <a href={element.watch_uri} >
                                                <img className="movie-image" src={element.thumbnail_url}>
                                                </img>
                                            </a>
                                        </AspectRatio>
                                    </CardContent>
                                    <CardContent
                                        sx={{
                                            width: "50%",
                                            height: "100%",
                                            boxShadow: 0,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 3, // 行数指定
                                                overflow: "hidden",
                                            }} color="text.primary">
                                            {element.name}<br></br>
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, width: "100%" }} color="text.primary">
                                            <ScheduleIcon fontSize="h6" color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                                        </Typography>
                                        <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                                        <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                                        <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                                        {/* <Typography sx={{
                                            fontSize: 12,
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1, // 行数指定
                                            overflow: "hidden",
                                        }} color="text.secondary">
                                            講師 :{typeof (element.tags[0]) !== "undefined" && element.tags[0].name}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1, // 行数指定
                                            overflow: "hidden",
                                        }} color="text.secondary">
                                            レベル／ジャンル:{typeof (element.tags[1]) !== "undefined" && element.tags[1].name}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1, // 行数指定
                                            overflow: "hidden",
                                        }} color="text.secondary">
                                            カテゴリ:{typeof (element.tags[2]) !== "undefined" && element.tags[2].name}
                                        </Typography> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </>
        )
    }
    return (
        <div>
            <Header />
            <Box sx={{ mt: 15, display: { xs: 'none', md: 'block', sm: 'block' } }}>
                <Grid container spacing={1} p={5}>
                    <Grid item xs={12}>
                        <PaginatedItems itemsPerPage={10} items={searchsMovielists} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 10, display: { md: 'none' } }}>
                <Grid container justify-content="center">
                    <Grid item xs={12}>
                        <InfiniteScrollPage data={searchsMovielists} />
                    </Grid>
                </Grid>
            </Box>
            <Footer title />
            <div>
                < Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
                    }
                    open={load}
                >
                    <CircularProgress color="inherit" />
                </Backdrop >
            </div>
        </div >
    )
};

export default Search;