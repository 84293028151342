import 'react-tabs/style/react-tabs.css';
import {
    Typography,
    Box,
    Divider
} from '@mui/material';
import '../App.css';
import Logo from "../images/UTLonline_logo.png";

const Error = () => {
    return (
        <Box sx={{ justifyContent: 'center', alignItems: "center", textAlign: "center" }}>
            <a href="/"><img src={Logo} className="logo-image" alt="logo" width="100"></img></a>
            <Divider />
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mt: 4, mb: 2, fontSize: 20 }}>申し訳ございません。</Typography>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, fontSize: 20 }}>動画を取得できませんでした。</Typography>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, fontSize: 20 }}>サーバのアクセスが混み合っている可能性があります。</Typography>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, fontSize: 20 }}>しばらくしてから再度アクセスしてください。</Typography>
        </Box>
    )
}

export default Error;