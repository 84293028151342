import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import '../App.css';
import { styled, alpha } from '@mui/material/styles';

import axiosBase from 'axios';
import axiosRetry from "axios-retry";
import 'react-tabs/style/react-tabs.css';
import {
    Backdrop,
    CircularProgress,
    Box,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AspectRatio from '@mui/joy/AspectRatio';
import Chip from '@mui/material/Chip';

//自作ライブラリ
import { useAuth } from '../hooks/use-auth';
import Header from '../components/header';
import Footer from '../components/footer';
import Scrollbar from "../components/scrollbar";
import Error from "../components/error";

//FAQメニュー
import Faq from "./Faq";
import { useMediaQuery } from 'react-responsive'

//metaタグ設定
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = (props) => {
    const isSmallScreen = useMediaQuery({ maxDeviceWidth: 599 }) // equivalent to 'sm' breakpoint
    const isMediumScreen = useMediaQuery({ minDeviceWidth: 600, maxDeviceWidth: 959 }) // equivalent to 'md' breakpoint
    const isLargeScreenOrMore = useMediaQuery({ minDeviceWidth: 960 }) // 'lg' breakpoint and more

    // console.log(props);

    const baseurl = props.baseurl
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState(true);

    const location = useLocation();

    const chipStyle = {
        backgroundColor: 'gray',
        color: 'white',
        fontSize: '10px',
        height: '24px',
        width: 'fit-content',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8px', // 4pxと12pxは上下および左右のpaddingです。必要に応じて変更してください。
        margin: '1px'
    };


    const navigate = useNavigate();
    const handleClick = (label) => {
        console.log(label);
        navigate(`/tag?tag=${label}`);
    };


    // const StyledBox = styled(Box)(({ theme }) => ({
    //     mt:1,
    //     // toolbar: theme.mixins.toolbar,
    //     // border: '1px solid',
    //   }));

    const getParameters = new Promise(function (resolve, reject) {
        // console.log("getParameters");
        const axios = axiosBase.create({
            baseURL: baseurl,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'D02MakhE9Sp70d8UJPEx',
            },
            responseType: 'json'
        })
        axiosRetry(axios, {
            retries: 3,
            shouldResetTimeout: true,
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                return retryCount * 1000; // time interval between retries
            },
            retryCondition: (error) => {
                console.log(error);
                return true;
            }
        })
        return (axios.get(`/v1/getparameters`)
            .then(res => {
                resolve(res.data.start_time);
            })
            .catch(e => {
                console.log(e)
                reject("パラメータ取得エラー")
            }));
    })

    useEffect(() => {
        setOpen(!open);
        // console.log('HomeでuseEffectが実行されました!')

        // console.log(process.env.REACT_APP_TRACKINGID);
        ReactGA.initialize(process.env.REACT_APP_TRACKINGID);
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });

        //人気ランキング表示
        const rankingmovies = new Promise(function (resolve, reject) {
            const axios = axiosBase.create({
                baseURL: baseurl,
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            axiosRetry(axios, {
                retries: 3,
                shouldResetTimeout: true,
                retryDelay: (retryCount) => {
                    console.log(`retry attempt: ${retryCount}`);
                    return retryCount * 1000; // time interval between retries
                },
                retryCondition: (error) => {
                    console.log(error);
                    return true;
                }
            })
            return (axios.post(props.ranking_data.apiuri, props.ranking_data)
                // return (axios.post(`/v1/getmoviesa`, props.ranking_data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    console.log(e)
                    reject("動画取得エラー")
                }));
        })

        //新着動画表示
        const newmovies = new Promise(async function (resolve, reject) {
            const axios = axiosBase.create({
                baseURL: baseurl,
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            if (props.new_data.condition == true) {
                try {
                    props.new_data.start_time = await getParameters;
                } catch (err) {
                    console.log(err);
                }
            }
            // console.log(props.new_data);
            axiosRetry(axios, {
                retries: 3,
                shouldResetTimeout: true,
                retryDelay: (retryCount) => {
                    console.log(`retry attempt: ${retryCount}`);
                    return retryCount * 1000; // time interval between retries
                },
                retryCondition: (error) => {
                    console.log(error);
                    return true;
                }
            })
            return (axios.post(props.new_data.apiuri, props.new_data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    console.log(e)
                    reject("動画取得エラー")
                }));
        })

        //おすすめ動画
        const recommendmovies = new Promise(function (resolve, reject) {
            const axios = axiosBase.create({
                baseURL: baseurl,
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'D02MakhE9Sp70d8UJPEx',
                },
                responseType: 'json'
            })
            axiosRetry(axios, {
                retries: 3,
                shouldResetTimeout: true,
                retryDelay: (retryCount) => {
                    console.log(`retry attempt: ${retryCount}`);
                    return retryCount * 1000; // time interval between retries
                },
                retryCondition: (error) => {
                    console.log(error);
                    return true;
                }
            })
            return (axios.post(props.recommend_data.apiuri, props.recommend_data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    console.log(e)
                    reject("動画取得エラー")
                }));
        })

        Promise.all([rankingmovies, newmovies, recommendmovies])
            .then((values) => {
                setPlayMovielists(values[0]);
                setNewMovielists(values[1]);
                setRecommendMovielists(values[2]);
                setOpen(false);
            })
            .catch((e) => {
                console.log(e);
                setResult(!result);
                setOpen(false);
            })
            .finally(() => {
                console.log('結果に関係なく処理')
                setOpen(false);
            })

    }, []) // 第2引数には副作用関数の実行タイミングを制御する依存データを記述  

    const auth = useAuth();
    const initialState = [];
    const [playsMovielists, setPlayMovielists] = useState(initialState);
    const [newMovielists, setNewMovielists] = useState(initialState);
    const [recommendMovielists, setRecommendMovielists] = useState(initialState);

    if (auth.isLoading) {
        return <div></div>;
    }

    //スマホ表示
    const InfiniteScrollPage = (data) => {
        return (
            <Grid
                container spacing={1} p={2}
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h1" sx={{ m: 2, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>本格ヨガスクールによるオンラインヨガ動画が見放題</Typography>
                </Box>
                {
                    data &&
                    data.data.map((element, index) => (
                        // <>
                        <Grid item xs={12} xl={12} key={index}>
                            <Card sx={{
                                width: "100%",
                                height: "100%",
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: 0,
                            }}
                            >
                                <CardContent
                                    sx={{
                                        width: "45%",
                                        boxShadow: 0,
                                        p: 0,
                                        m: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <AspectRatio
                                        ratio="1/1"
                                    >
                                        <a href={element.watch_uri} >
                                            <img className="movie-image" src={element.thumbnail_url}>
                                            </img>
                                        </a>
                                    </AspectRatio>
                                </CardContent>
                                <CardContent
                                    sx={{
                                        width: "50%",
                                        height: "100%",
                                        boxShadow: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3, // 行数指定
                                            overflow: "hidden",
                                        }} color="text.primary">
                                        {element.name}<br></br>
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, width: "100%" }} color="text.primary">
                                        <ScheduleIcon fontSize="h6" color="text.secondary" />:{(Math.floor(element.duration / 60))}分{(Math.floor(element.duration % 60))}秒
                                    </Typography>
                                    <Chip style={chipStyle} label={typeof (element.tags[0]) !== "undefined" && element.tags[0].name} size="small" onClick={() => handleClick(element.tags[0].name)} />
                                    <Chip style={chipStyle} label={typeof (element.tags[1]) !== "undefined" && element.tags[1].name} size="small" onClick={() => handleClick(element.tags[1].name)} />
                                    <Chip style={chipStyle} label={typeof (element.tags[2]) !== "undefined" && element.tags[2].name} size="small" onClick={() => handleClick(element.tags[2].name)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                <Faq />
            </Grid >
        )
    }

    if (result) {
        return (
            // <HelmetProvider>
            //     <Helmet>
            //         <title>本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50</title>
            //         <meta name="description" content="わあああ" />


            <div>
                <Helmet>
                    <title>本格ヨガスクールによるオンラインヨガ動画サイト：UTL YOGA ONLINE TOP50</title>
                    <meta
                        name="description"
                        content="「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。新着動画、人気動画、おすすめ動画等をランキングで紹介しています。"
                    />
                </Helmet>
                <Header />
                {
                    isLargeScreenOrMore && (
                        <Box sx={{ mt: 15, display: { xs: 'none', md: 'block', sm: 'block' } }}>
                            <Grid container spacing={1} p={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="h1" sx={{ m: 2, fontSize: 34, textAlign: 'center', fontWeight: 'bold' }}>本格ヨガスクールによるオンラインヨガ動画が見放題</Typography>
                                    </Box>
                                    <Typography variant="h2" sx={{ ml: 6, mb: 2, fontSize: 20 }}>新着動画</Typography>
                                    <Scrollbar data={newMovielists} />
                                    <Typography sx={{ mt: 6, ml: 6, fontSize: 20 }}>人気ランキング(閲覧数順)</Typography>
                                    <Scrollbar data={playsMovielists} />
                                    <Typography sx={{ mt: 6, ml: 6, fontSize: 20 }}>スタッフのおすすめ動画</Typography>
                                    <Scrollbar data={recommendMovielists} />
                                    <Faq />
                                </Grid>
                            </Grid >
                        </Box >
                    )
                }
                {
                    (isSmallScreen || isMediumScreen) && newMovielists && (
                        <Box sx={{ mt: 10 }}>
                            <InfiniteScrollPage data={newMovielists} />
                        </Box>
                    )
                }
                <Footer />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
                    }
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop >
            </div >
        )
    } else {
        return (
            <Error />
        )
    }
};

export default Home;