import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import "./styles.css";
import {
    TextField,
    Autocomplete,
    Typography,
} from '@mui/material';

export default function App() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const { control, handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: query.get("title"),
            auther: (query.get("auther") ? query.get("auther") : ""),
            level: (query.get("level") ? query.get("level") : ""),
            category: (query.get("category") ? query.get("category") : ""),
        },
    });

    const auther = [
        'Alan Finger',
        'Desiree Rumbaugh',
        'HAYATO',
        'HIKARU',
        'Jo Phee',
        'Kino MacGregor',
        'MICHi',
        'Mark Robberds',
        'NATSUKO',
        'Nammy',
        'Noah Maze',
        'Rebbeca Pflaum',
        'Sarah Platt-Finger',
        'Simon Borg-Olivier',
        'Twee Merrigan',
        'YOKO',
        'YUUKA',
        'カウ',
        'つぐみ',
        'なずな',
        '合津静',
        '吉村均',
        '松村憲',
        '浦智子',
        '中村尚人',
        '伊藤ゆり',
        '倉橋陽子',
        '小中千恵',
        '山口陽平',
        '村上華子',
        '梅田陽一',
        '蛭田千恵',
        '西岡ゆき',
        'アクロアキ',
        'ミスミリエ',
        '向井田みお',
        '新倉美代子',
        '渋木さやか',
        '石井まいこ',
        '鈴木修一郎',
        'シュミッツ茂仁香',
    ]

    const level = [
        'All Level',
        '上級',
        '中級',
        '初級',
        '哲学',
        '座学',
        '瞑想',
        'セルフケア',
    ];

    const category = [
        'シニアヨガ',
        '楽しむヨガ',
        '流派別ヨガ',
        '瞑想＆哲学',
        '初めてのヨガ',
        '専門家＆ケア',
        '音で学ぶヨガ',
        '海外動画クラス',
        '海外動画クラス(吹替版)',
        '女性のためのヨガ',
    ];

    let navigate = useNavigate();
    const onSubmit = async (data) => {
        console.log(data)
        navigate({ pathname: `/detailedsearch?title=${data.title}&auther=${data.auther == null ? "" : data.auther}&level=${data.level == null ? "" : data.level}&category=${data.category == null ? "" : data.category}` });
        window.location.reload()
    }

    const validationRules = {
        name: {
            required: '空白です。入力してください。'
            // minLength: { value: 4, message: '4文字以上で入力してください。' }
        }
    }

    return (
        <>
            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>詳細検索</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="title"
                    rules={validationRules.name}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            fullWidth
                            type="text"
                            label={<>
                                <span style={{ display: 'inline', color: 'text.primary' }}>キーワード：講師、ヨガの種類など</span>
                                <span style={{ fontWeight: 'bold', color: 'red' }}>【必須】</span>
                            </>
                            }
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="auther"
                    // rules={validationRules.name}
                    render={({ field, fieldState }) => (
                        <Autocomplete
                            {...field}
                            required
                            fullWidth
                            options={auther}
                            renderInput={(params) => <TextField {...params}
                                label={<>
                                    <span style={{ display: 'inline', color: 'text.primary' }}>講師名選択してください</span>
                                    <span style={{ color: 'text.primary' }}>【任意】</span>
                                </>}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                            />}
                            onChange={(event, value) => {
                                setValue('auther', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="level"
                    // rules={validationRules.name}
                    render={({ field, fieldState }) => (
                        <Autocomplete
                            {...field}
                            required
                            fullWidth
                            options={level}
                            renderInput={(params) => <TextField {...params}
                                label={<>
                                    <span style={{ display: 'inline', color: 'text.primary' }}>レベル／ジャンルを選択してください</span>
                                    <span style={{ color: 'text.primary' }}>【任意】</span>
                                </>}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                            />}
                            onChange={(event, value) => {
                                setValue('level', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="category"
                    // rules={validationRules.name}
                    render={({ field, fieldState }) => (
                        <Autocomplete
                            {...field}
                            required
                            fullWidth
                            options={category}
                            renderInput={(params) => <TextField {...params}
                                label={<>
                                    <span style={{ display: 'inline', color: 'text.primary' }}>カテゴリーを選択してください</span>
                                    <span style={{ color: 'text.primary' }}>【任意】</span>
                                </>}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                            />}
                            onChange={(event, value) => {
                                setValue('category', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                });
                            }}
                        />
                    )}
                />
                <input type="submit"
                    value="検索"
                />
                {/* <PaginatedItems itemsPerPage={4} items={searchsMovielists} /> */}
            </form>
            {/* < Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
                }
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop > */}
        </>
    );
}
