import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Faq = () => {

    const url = useLocation().pathname;

    return (
        url == "/" ? (
            <Box sx={{ m: 2, ml: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ m: 2, mt: 4, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>よくある質問</Typography>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>UTL YOGA ONLINE TOP50とは?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            「UTL YOGA ONLINE TOP50」は、国内外の有名ヨガ講師による約900本の動画を紹介するサイトです。新着動画、人気動画、おすすめ動画等をランキングで紹介しています。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>UTL YOGA ONLINE TOP50の利用料金は??</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            <a href="https://yogahodai.underthelight.jp/gold">
                                UTL YOGA ONLINEゴールドメンバー
                            </a>
                            になるとすべての動画を定額(月額7,000円)でお楽しみいただけます。追加料金は発生しません。
                        </Typography>

                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>どこで視聴できますか?</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ ml: 3, mr: 1 }}>
                        <Typography sx={{ fontSize: 15 }}>
                            インターネット接続があれば、どこにいても、お好きなときに視聴いただけます。パソコン、スマホ、タブレット等からアカウントにログインして瞬時にお楽しみいただけます。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>キャンセルするには?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            オンラインで簡単にキャンセルできます。キャンセル料金は一切かかりません。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>UTL YOGA ONLINEで何が視聴できますか?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            UTL YOGA ONLINE はオリジナル動画だけでなく、ライブクラス、見逃し配信、特別ワークショップなどの豊富なコンテンツを用意しています。いつでも好きなだけ追加料金なしで視聴できます。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        ) : url == "/newvideos" ? (
            <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ m: 2, mt: 4, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>よくある質問</Typography>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>新しい動画はどのくらいの頻度で追加されますか？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            新しい動画は毎週に数本のペースで追加されます。最新の動画情報は「新着動画」ページでご確認いただけます。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>新着動画のお知らせを受け取る方法は？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            新着動画のお知らせは会員サイトにて掲載しております。また、SNSのフォローもおすすめします。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        ) : url == "/rankingvideos" ? (
            <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ m: 2, mt: 4, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>よくある質問</Typography>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>人気動画のランキングはどのように決まりますか？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            人気動画のランキングは、今までの閲覧回数に基づいて決定されます。ランキングは、動画がどれだけユーザーに受け入れられているかを示しています。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>人気動画のランキングはどのくらいの頻度で更新されますか？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            人気動画のランキングは随時更新されます。これにより、ユーザーは常に最新の人気動画をチェックすることができます。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        ) : url == "/recommendvideos" ? (
            <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ m: 2, mt: 4, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>よくある質問</Typography>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>どのようなヨガのスタイルがありますか？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            当サイトでは、ハタヨガ、アシュタンガヨガ、アイアンガーヨガ、マタニティヨガ、シニアヨガなど、さまざまなヨガスタイルの動画を提供しています。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', mb: 1 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h3" sx={{ fontSize: 15 }}>どのように動画を選びますか？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ fontSize: 15 }}>
                            動画はレベル（初級、中級、上級など）、ヨガのスタイル、講師名などでキーワード検索して選ぶことができます。
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        ) : (
            null
        )
    )
}

export default Faq;
